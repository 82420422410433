import React, { useState, useEffect, useContext } from 'react'
import { currencyFormat } from 'simple-currency-format';
import { Box, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import BoxSummary from '../components/Dashboard/BoxSummary';
import { get_records } from '../utils/apis/records';
import { getTempTotal } from '../utils/functions/getTotal';
import { get_customers } from '../utils/apis/customers';
import { get_renovation_month, get_renovation_nexttendays } from '../utils/apis/home';
import Record from '../components/Records/Record';
import RowRenovation from '../components/Dashboard/RowRenovation';
import Loading from '../components/Loading/Loading';
import 'dayjs/locale/es-mx'

const Dashboard = () =>
{
  const today = dayjs().format('YYYY-MM-DD')
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ itemsCustomers, setItemsCustomers ] = useState();
  const [ itemsPostpago, setItemsPostpago ] = useState();
  const [ totalPospago, setTotalPospago ] = useState();
  const [ totalPrepago, setTotalPrepago ] = useState();
  const [ itemsPrepago, setItemsPrepago ] = useState();
  const [ totalAmount, setTotalAmount ] = useState();
  const [ records, setRecords ] = useState();
  const [ renovation, setRenovation ] = useState();
  const tenDays = dayjs().add(10, 'day').format('YYYY-MM-DD');
  const [barChart, setBarChart] = useState();
  const getRecords = async (startDate, endDate) =>
  {
    const data = {
      filter: [ dayjs(startDate).format('DD/MM/YYYY'), dayjs(endDate).format('DD/MM/YYYY'), ]
    }
    const res = await get_records(data);
    const postpago = res.records.filter(item => item.type_contract === 'Postpago');
    const prepago = res.records.filter(item => item.type_contract === 'Prepago')
    setRecords(res.records.reverse().slice(0, 9))
    setTotalPospago(getTempTotal(postpago));
    setTotalPrepago(getTempTotal(prepago));
    setTotalAmount(getTempTotal(res.records))
    const res2 = await get_customers();
    setItemsCustomers(res2.length)
    setItemsPostpago(res2.filter(item => item.type_contract === 'Postpago').length);
    setItemsPrepago(res2.filter(item => item.type_contract === 'Prepago').length);


    const res3 = await get_renovation_nexttendays();
    const warningDate = Date.parse(today);
    const responses = res3.filter(item => Date.parse(dayjs(item.limit_date_billing).format('YYYY-MM-DD')) >= warningDate)
    responses.sort((a,b)=>new Date(a.limit_date_billing )- new Date(b.limit_date_billing))
    setRenovation(responses.slice(0, 9))
  }

  useEffect(() =>
  {
    setStartDate(dayjs().startOf('month').format('YYYY-MM-DD'));
    setEndDate(dayjs().endOf('month').format('YYYY-MM-DD'))
    getRecords(dayjs().startOf('month'), dayjs().endOf('month'))

  }, []);


  return (
    <>
      <div className='w-full gap-1 overflow-x-auto pt-2 px-2'>
        <div className='mx-2'>
          <div className='flex flex-col md:flex-row justify-between  my-3'>
            <Box className='flex flex-row justify-start items-end'>
              <h1 className='text-4xl font-semibold'>Dashboard </h1>
              <h4 className='text-xl font-light mx-1 capitalize'> - { dayjs(startDate).locale('es-mx').format('MMMM') }</h4>
            </Box>
          </div>
        </div>
        <section className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2'>

          <BoxSummary title='Usuario postpago' value={ itemsPostpago } color='blue' />
          <BoxSummary title='Usuarios prepago' value={ itemsPrepago } color='pink' />
          <BoxSummary title='Total usuarios' value={ itemsCustomers } color='orange' />

          <BoxSummary title='Total Postpago' value={ totalPospago ? `${ currencyFormat(totalPospago, 'es-MX', 'MXN', 2) } mxn` : `${ currencyFormat(0, 'es-MX', 'MXN', 2) } mxn` } color='cyan' />
          <BoxSummary title='Total Prepago' value={ totalPrepago ? `${ currencyFormat(totalPrepago, 'es-MX', 'MXN', 2) } mxn` : `${ currencyFormat(0, 'es-MX', 'MXN', 2) } mxn` } color='purple' />
          <BoxSummary title='Total de ingresos' value={ totalAmount ? `${ currencyFormat(totalAmount, 'es-MX', 'MXN', 2) } mxn` : `${ currencyFormat(0, 'es-MX', 'MXN', 2) } mxn` } color='green' />
        </section>
        <section className='my-4'>
          <Box className="grid grid-cols-1 md:grid-cols-2 py-4 gap-2">
            <Box className='border rounded-lg'>
              <Text className='font-bold uppercase text-xl pt-4 px-2'>Ultimas renovaciones</Text>
              { records ? records.map(item => (
                <Record key={ item._id } item={ item } dashboard={ true } />
              ))

                :
                <Loading />
              }
            </Box>
            <Box className='border rounded-lg '>
              <Text className='font-bold uppercase text-xl pt-4 px-2'>Proximos a vencer</Text>

              { renovation && <Box className='border-b last:border-non px-2 my-2 grid grid-cols-3 gap-2 py-4 place-items-center'>
                <Box>
                </Box>
                <Box>
                  <Text>Fecha limite</Text>
                </Box>
                <Box>
                  <Text>Cuenta</Text>
                </Box>

              </Box>
              }
              { renovation ?
                renovation.map(item => (
                  <RowRenovation item={ item } today={ today } tenDays={ tenDays } />
                ))

                :
                <Loading />
              }


            </Box>


          </Box>
        </section>
      </div>
    </>
  )
}

export default Dashboard
