import React, { useState, useEffect, useContext } from 'react'
import { HiCheck, HiX, HiOutlineArrowsExpand, HiPencilAlt } from "react-icons/hi";
import { MdOutlineFullscreenExit, MdPhone, MdSmartphone, MdEmail} from "react-icons/md";

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar'
import { get_plans } from '../../utils/apis/plans'

import {
  Divider, Stack, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, FormControl, FormLabel, FormHelperText, Input, Select, InputGroup, InputLeftAddon, InputLeftElement, InputRightElement, InputRightAddon, Textarea, Button, Text, IconButton
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomerContext from '../../contexts/Customers/customerContext';
dayjs.extend(calendar)


const ModalCustomers = ({ onOpen, onClose, isOpen, item, edit }) => {
  const customerContext = useContext(CustomerContext);
  const { postNewCustomer, updateCustomer } = customerContext;
  const [fullScreen, setFullScreen] = useState(false);
  const [changeEdit, setChangeEdit] = useState();
  const [loading, setLoading] = useState(false)
  const [plans, setPlans] = useState();



  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      setLoading(true)
      data.start_from = dayjs(data.start_from).format('DD/MM/YYYY')
      data.date_billing = dayjs(data.date_billing).format('DD/MM/YYYY');
      data.limit_payday = Number(data.limit_payday);

      console.log(data)
      try {

        if (item === null) {
          await postNewCustomer(data);
          formik.resetForm()
        } else {
          data._id = item._id;
          await updateCustomer(data)
          formik.resetForm()
        }
        onClose()
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
  })
  const close = () => {
    formik.resetForm()
    setChangeEdit(true)
    onClose()
  }
  const toggleView = () => {
    setFullScreen(!fullScreen)
  }

  const togglechangeEdit = () => {
    setChangeEdit(!changeEdit)
  }
  const listPlans = async () => {
    const response = await get_plans()
    setPlans(response)
  }

  const changeDate = (date) => {

    formik.setFieldValue('start_from', date)
    const facturacion = dayjs(date).add(1, 'month').format('YYYY-MM-DD');
    formik.setFieldValue('date_billing', facturacion)

  }

  const changeTypeContract = (typeContract) =>{
    formik.setFieldValue('type_contract', typeContract)
    if(typeContract === 'Postpago'){
      formik.setFieldValue('limit_payday', 10);
    }
    if(typeContract === 'Prepago'){
      formik.setFieldValue('limit_payday', 0);
    }
  }


  useEffect(() => {
    listPlans()
    if (item) {
      formik.setFieldValue('num_account', item.num_account);
      formik.setFieldValue('full_name', item.full_name);
      formik.setFieldValue('email', item.email);
      formik.setFieldValue('phone', item.phone);
      formik.setFieldValue('cellphone', item.cellphone);
      formik.setFieldValue('latitude', item.latitude);
      formik.setFieldValue('longitude', item.longitude);
      formik.setFieldValue('address', item.address);
      formik.setFieldValue('address_street', item.address_street);
      formik.setFieldValue('address_num_ext', item.address_num_ext);
      formik.setFieldValue('address_int', item.address_int);
      formik.setFieldValue('address_suburb', item.address_suburb);
      formik.setFieldValue('address_delegation', item.address_delegation);
      formik.setFieldValue('address_city', item.address_city);
      formik.setFieldValue('address_state', item.address_state);
      formik.setFieldValue('address_country', item.address_country);
      formik.setFieldValue('id_type_plan', item.id_type_plan);
      formik.setFieldValue('type_contract', item.type_contract);
      formik.setFieldValue('address_country', item.address_country);
      formik.setFieldValue('limit_date_billing', item.limit_date_billing);
      formik.setFieldValue('limit_payday', item.limit_payday);
      formik.setFieldValue('payment_reference', item.payment_reference);
      let start = dayjs(item.start_from).format('YYYY-MM-DD');
      let billing = dayjs(item.date_billing).format('YYYY-MM-DD');
      formik.setFieldValue('start_from', start);
      formik.setFieldValue('date_billing', billing);
    }

  }, [item])

  return (
    <>

      <Modal  closeOnOverlayClick={false} size={fullScreen ? 'full' : 'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={close}>
        <ModalOverlay bg='blackAlpha.500' />
        <ModalContent maxW="64rem" >


          <ModalBody className='overflow-auto'>

            <FormControl>
              <div className='text-2xl font-semibold mb-4 flex flex-row justify-between'>
                <h1>{!item?._id ? 'Agregar Cliente' : 'Editar Cliente'}</h1>
                <div className='flex flex-row'>
                  <IconButton aria-label={fullScreen ? 'Exit fullscreen' : 'Full screen'} title={fullScreen ? 'Exit fullscreen' : 'Full screen'} variant='ghost'  fontSize='20px' icon={fullScreen ? <MdOutlineFullscreenExit /> : <HiOutlineArrowsExpand />} onClick={toggleView} />
                  <IconButton aria-label='Cerrar' title="Cerrar" variant='ghost'  fontSize='20px' icon={<HiX />} onClick={close} />
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <Text fontSize='2xl' className='py-2'>Información del Cliente</Text>
              </div>
              <Divider />
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='num_account'># Cuenta</FormLabel>
                  <InputGroup >
                    <Input type='text' placeholder='# Cuenta'
                      value={formik.values.num_account}
                      isInvalid={formik.touched.num_account && formik.errors.num_account}
                      isRequired
                      onChange={(e) => formik.setFieldValue('num_account', e.target.value)}
                    />


                  </InputGroup>
                  {formik.touched.num_account && formik.errors.num_account && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.num_account}</FormHelperText>}
                </Stack>
                <Stack className='w-full'>
                  <FormLabel htmlFor='name'>Nombre</FormLabel>
                  <InputGroup >
                    <Input type='text' placeholder='Nombre'
                      value={formik.values.full_name}
                      isInvalid={formik.touched.full_name && formik.errors.full_name}
                      isRequired
                      onChange={(e) => formik.setFieldValue('full_name', e.target.value)}
                    />

                  </InputGroup>
                  {formik.touched.full_name && formik.errors.full_name && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.full_name}</FormHelperText>}
                </Stack>

              </div>
              <div  className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
              <Stack className='w-full'>
                  <FormLabel htmlFor='email'>Correo</FormLabel>
                  <InputGroup >
                  <InputLeftElement
                      pointerEvents='none'
                      children={<MdEmail className='text-lg text-gray-300'/>}
                    />
                    <Input type='email' placeholder='Correo'
                      value={formik.values.email}
                      isInvalid={formik.touched.email && formik.errors.email}
                      isRequired
                      onChange={(e) => formik.setFieldValue('email', e.target.value)}
                    />

                  </InputGroup>
                  {formik.touched.email && formik.errors.email && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.email}</FormHelperText>}
                </Stack>
              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='phone'>Teléfono fijo</FormLabel>
                  <InputGroup >
                    <InputLeftElement
                      pointerEvents='none'
                      children={<MdPhone className='text-lg text-gray-300'/>}
                    />
                    <Input type='tel' placeholder='Teléfono fijo'
                      value={formik.values.phone}
                      isInvalid={formik.touched.phone && formik.errors.phone}
                      isRequired
                      pattern="[0-9]{2} [0-9]{8}"
                      onChange={(e) => { formik.setFieldValue('phone', e.target.value) }}
                    />

                  </InputGroup>
                  {formik.touched.phone && formik.errors.phone && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.phone}</FormHelperText>}
                </Stack>
                <Stack className='w-full'>
                  <FormLabel htmlFor='cellphone'>Celular</FormLabel>
                  <InputGroup >
                  <InputLeftElement
                      pointerEvents='none'
                      children={<MdSmartphone className='text-lg text-gray-300'/>}
                    />
                    <Input type='tel' placeholder='Celular'
                      value={formik.values.cellphone}
                      isInvalid={formik.touched.cellphone && formik.errors.cellphone}
                      isRequired
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      onChange={(e) => { formik.setFieldValue('cellphone', e.target.value) }}
                    />

                  </InputGroup>
                  {formik.touched.cellphone && formik.errors.cellphone && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.cellphone}</FormHelperText>}
                </Stack>
              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='id_type_plan'>Tipo de plan</FormLabel>
                  <InputGroup >

                    <Select placeholder='Selecciona un plan' value={formik.values.id_type_plan}
                      isInvalid={formik.touched.id_type_plan && formik.errors.id_type_plan}
                      isRequired
                    
                      onChange={(e) => formik.setFieldValue('id_type_plan', e.target.value)}
                    >
                      {plans && plans.map(item => {
                        return (
                          <option key={item._id} value={item._id} className="text-gray-800">{item.name}</option>
                        )
                      })}
                    </Select>



                  </InputGroup>
                  {formik.touched.id_type_plan && formik.errors.id_type_plan && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.id_type_plan}</FormHelperText>}
                </Stack>
                <Stack className='w-full'>
                  <FormLabel htmlFor='contract'>Contrato</FormLabel>
                  <InputGroup >
                    <Select placeholder='Selecciona un tipo' value={formik.values.type_contract}
                      isInvalid={formik.touched.type_contract && formik.errors.type_contract}
                      isRequired
                    
                      onChange={(e) => changeTypeContract(e.target.value)}
                    >
                      <option value='Prepago'>Prepago</option>
                      <option value='Postpago'>Postpago</option>
                    </Select>
                  </InputGroup>
                  {formik.touched.type_contract && formik.errors.type_contract && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.type_contract}</FormHelperText>}
                </Stack>
              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='start_from'>Fecha de Inicio</FormLabel>
                  <InputGroup >
                    <Input type='date' placeholder='start_from'
                      value={formik.values.start_from}
                      isInvalid={formik.touched.start_from && formik.errors.start_from}
                      isRequired
                      pattern="\d{4}-\d{2}-\d{2}"
                      onChange={(e) => changeDate(e.target.value)}
                    />


                  </InputGroup>
                  {formik.touched.start_from && formik.errors.start_from && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.start_from}</FormHelperText>}
                </Stack>
                <Stack className='w-full'>
                  <FormLabel htmlFor='date_billing'>Fecha de facturación</FormLabel>
                  <InputGroup >
                    <Input type='date' placeholder='Fecha de Corte'
                      value={formik.values.date_billing}
                      isInvalid={formik.touched.date_billing && formik.errors.date_billing}
                      isRequired
                      pattern="\d{4}-\d{2}-\d{2}"
                      disabled={true}
                    />

                  </InputGroup>
                  {formik.touched.date_billing && formik.errors.date_billing && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.date_billing}</FormHelperText>}
                </Stack>
              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='limit_payday'>Limite de dias</FormLabel>
                  <InputGroup >
                    <Input type='number' placeholder='10'
                      value={formik.values.limit_payday}
                      isInvalid={formik.touched.limit_payday && formik.errors.limit_payday}
                      isRequired
                      min="1"
                      max="10"
                      onChange={(e) => { formik.setFieldValue('limit_payday', e.target.value) }}
                    />


                  </InputGroup>
                  {formik.touched.limit_payday && formik.errors.limit_payday && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.limit_payday}</FormHelperText>}
                </Stack>
                <Stack className='w-full'>
                  <FormLabel htmlFor='limit_date_billing'>Fecha de Corte</FormLabel>
                  <InputGroup >
                    <Input type='date' placeholder='Fecha Limite de Corte'
                      value={formik.values.limit_date_billing}
                      isInvalid={formik.touched.limit_date_billing && formik.errors.limit_date_billing}
                      pattern="\d{4}-\d{2}-\d{2}"
                      disabled={true}
                      onChange={(e) => { formik.setFieldValue('limit_date_billing', e.target.value) }}
                    />

                  </InputGroup>
                  {formik.touched.limit_date_billing && formik.errors.limit_date_billing && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.limit_date_billing}</FormHelperText>}
                </Stack>
              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='payment_reference'>Referencia de pago</FormLabel>
                  <InputGroup >
                    <Input type='text' placeholder='Referencia de pago'
                      value={formik.values.payment_reference}
                      isInvalid={formik.touched.payment_reference && formik.errors.payment_reference}
                      isRequired
                      onChange={(e) => formik.setFieldValue('payment_reference', e.target.value)}
                    />

                  </InputGroup>
                  {formik.touched.payment_reference && formik.errors.payment_reference && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.payment_reference}</FormHelperText>}
                </Stack>
              </div>
              <Text fontSize='2xl' className='pt-6'>Dirección</Text>
              <Divider />
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='latitude'>Latitud</FormLabel>
                  <InputGroup >
                    <Input type='text' placeholder='0'
                      value={formik.values.latitude}
                      isInvalid={formik.touched.latitude && formik.errors.latitude}
                      isRequired
                      onChange={(e) => { formik.setFieldValue('latitude', e.target.value) }}
                    />

                  </InputGroup>
                  {formik.touched.latitude && formik.errors.latitude && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.latitude}</FormHelperText>}
                </Stack>
                <Stack className='w-full'>
                  <FormLabel htmlFor='longitude'>Longitud</FormLabel>
                  <InputGroup >
                    <Input type='text' placeholder='0'
                      value={formik.values.longitude}
                      isInvalid={formik.touched.longitude && formik.errors.longitude}
                      isRequired
                      onChange={(e) => { formik.setFieldValue('longitude', e.target.value) }}
                    />

                  </InputGroup>
                  {formik.touched.longitude && formik.errors.longitude && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.longitude}</FormHelperText>}
                </Stack>
              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='address'>Dirección completa</FormLabel>
                  <InputGroup >
                    <Textarea type='text' placeholder='Dirección completa'
                      value={formik.values.address}
                      isInvalid={formik.touched.address && formik.errors.address}
                      isRequired
                      onChange={(e) => formik.setFieldValue('address', e.target.value)}
                    />

                  </InputGroup>
                  {formik.touched.address && formik.errors.address && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.address}</FormHelperText>}
                </Stack>
              </div>


            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' colorScheme='gray' mr={3} onClick={close}>
              Cerrar
            </Button>
            <Button colorScheme='yellow' onClick={() => formik.handleSubmit()}> {!item?._id ? 'Agregar' : 'Actualizar'}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalCustomers;

function initialValues () {
  return {
    num_account: '',
    full_name: '',
    email: '',
    phone: '',
    cellphone: '',
    latitude: '',
    longitude: '',
    address: '',
    id_type_plan: '',
    payment_reference: '',
    type_contract: '',
    start_from: '',
    limit_date_billing: '',
    date_billing: '',
    limit_payday: ''
  }
}

function validationSchema () {
  const phoneRegExp = new RegExp('[0-9]{10}');
  const emailRegExp = /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return {
    num_account: Yup.string().required('Es requerido'),
    full_name: Yup.string().required('Es requerido'),
    email: Yup.string().matches(emailRegExp, 'No parece ser un email'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    cellphone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    latitude: Yup.string(),
    longitude: Yup.string(),
    address: Yup.string().required('La direccion es requerida'),
    payment_reference: Yup.string().required('La referencia de pago es requerida'),
    id_type_plan: Yup.string().required('El plan es requerido'),
    type_contract: Yup.string().required('ID de contrato'),
    start_from: Yup.string().required('Fecha de inicio'),
    date_billing: Yup.string().required('Fecha de facturación'),
    limit_date_billing: Yup.string(),
    limit_payday: Yup.number().required('Fecha limite de pago'),
  }
}