import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  IconButton,
  Stack,
  Skeleton,
  Spinner,
  Box,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { HiX, HiSearch } from "react-icons/hi";
import { get_renovation_nexttendays } from '../utils/apis/home'
import RenovationTable from '../components/Home/RenovationTable'

import ModalRenovation from '../components/Home//Modal';

const Home = () =>
{
  const [ items, setItems ] = useState([]);
  const [ itemsPostpago, setItemsPostpago ] = useState([]);
  const [ itemsPrepago, setItemsPrepago ] = useState([]);
  const [ item, setItem ] = useState(null)
  const [ list, setList ] = useState(null);
  const [ searchText, setSearchText ] = useState('');
  const [ isOpen, setIsOpen ] = useState(false)
  const today = dayjs().format('YYYY-MM-DD');
  const [ loading, setLoading ] = useState(true);
  const tenDays = dayjs().add(10, 'day').format('YYYY-MM-DD');

  const onOpen = (item) =>
  {
    setIsOpen(true);
    setItem(item);
  }
  const onClose = (refresh) =>
  {
    setIsOpen(false);
    setItem(null);
    setLoading(true)
    getInfo()
    if(refresh !== null){
      clear();
    }
  }

  // useEffect(() =>
  // {
  //   getInfo()
  // }, [ loading ])

  useEffect(() =>
  {
    getInfo()
  }, [])
  const getInfo = async () =>
  {
    const res = await get_renovation_nexttendays();

    const warningDate = Date.parse(tenDays);
    const responses = res.filter(item => Date.parse(dayjs(item.limit_date_billing).format('YYYY-MM-DD')) <= warningDate)
    setItems(responses)
    setItemsPostpago(responses.filter(item => item.type_contract === 'Postpago'));
    setItemsPrepago(responses.filter(item => item.type_contract === 'Prepago'));
    setList(responses);
    setLoading(false)
  }
  const searchBd = (texto) =>
  {
    setSearchText(texto);
    const originalList = list;
    const filters = searchText.split(" ");
    let resultFilter = originalList;
    for (let fl of filters) {
      resultFilter = resultFilter.filter((item) =>
      {
        const itemData = `${ item.full_name.toUpperCase() } ${ item.num_account.toUpperCase() }`;
        const textData = fl.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });
    }
    setItems(resultFilter);
    setItemsPostpago(
      resultFilter.filter((item) => item.type_contract === "Postpago")
    );
    setItemsPrepago(
      resultFilter.filter((item) => item.type_contract === "Prepago")
    );
  };

  const clear = () =>
  {
    setSearchText("");
    setItems(list);
    setItemsPostpago(
      list.filter((item) => item.type_contract === "Postpago")
    );
    setItemsPrepago(
      list.filter((item) => item.type_contract === "Prepago")
    );
  }
  return (
    <>

      <div className='w-full gap-1 overflow-x-auto pt-2 px-2'>
        <div className='mx-2'>
          <div className='flex flex-col md:flex-row justify-between  my-3'>
            <Box className='flex flex-col justify-between'>
              <h1 className='text-4xl font-semibold'>Resumen de cuentas </h1>
              <h4 className='text-xl font-light mb-4'>Proximos 10 días </h4>
            </Box>
            <Box className="my-2 md:my-0 flex flex-row gap-2">
              <Input
                placeholder="Buscador"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Box className="flex flex-row gap-2">
                <IconButton
                colorScheme="yellow"
                  icon={<HiSearch />}
                  onClick={() => searchBd(searchText)}
                />
                {searchText !== "" && (
                  <IconButton
                    colorScheme="gray"
                    variant='ghost'
                    icon={<HiX />}
                    onClick={() => {
                      clear()
                    }}
                  />
                )}
              </Box>
            </Box>


          </div>
          <div className='py-1 rounded flex flex-col items-center justify-center overflow-auto mt-4'>
            { loading && (
              <div className="my-4">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </div>
            ) }
            { !loading && items ? <Tabs variant='soft-rounded' colorScheme='yellow' className='w-full'>
              <TabList>
                <Tab>Todos  { items.length }</Tab>
                <Tab>Postpago { itemsPostpago.length }</Tab>
                <Tab>Prepago  { itemsPrepago.length }</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  { items !== null ?
                    <RenovationTable items={ items } today={ today } tenDays={ tenDays } openModal={ onOpen } />
                    :
                    null
                  }
                </TabPanel>
                <TabPanel>
                  { items !== null ?
                    <RenovationTable items={ itemsPostpago } today={ today } tenDays={ tenDays } openModal={ onOpen } />
                    :
                    null
                  }
                </TabPanel>
                <TabPanel>
                  { items !== null ?
                    <RenovationTable items={ itemsPrepago } today={ today } tenDays={ tenDays } openModal={ onOpen } />
                    :
                    null
                  }
                </TabPanel>
              </TabPanels>
            </Tabs>
              :
              null
            }

          </div>
        </div>
      </div>
      <ModalRenovation isOpen={ isOpen } onOpen={ onOpen } onClose={ onClose } item={ item } />
    </>
  )
}

export default Home
