import React, { useContext, useEffect } from 'react'
import {
    Button,
    Input,
    Box,
    Text
} from '@chakra-ui/react'
import md5 from 'md5';
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/Auth/authContext';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Logo from '../../assets/logo.png'
import {
    DASHBOARD,
    HOME
} from '../../routes/types'
import ToggleButton from '../../components/ToggleButton';

const Login = () => {
    const navigate = useNavigate()
    const authContext = useContext(AuthContext)
    const { login, autenticado } = authContext;
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formData) => {
            let data = {
                username: formData.username,
                password: md5(formData.password)
            }
            await login(data)
        }
    })

    useEffect(() => {

        if (autenticado) {
            navigate(DASHBOARD);
        }
    }, [autenticado, navigate])

    return (
        <>
            <div className='w-full'>
                <Box className='flex flex-row justify-end'>
                    <ToggleButton />
                </Box>
                <div className='flex flex-row items-center justify-center w-full gap-1'>
                    <div className='grid grid-cols-1 items-start justify-start gap-1 w-8/12 md:w-6/12 lg:w-4/12'>
                        <Box className='flex flex-col items-center justify-center'>
                            <img src={Logo} alt="Logo GC" className='w-56 bg-white rounded-full border-2 border-green-700' />
                        </Box>
                        <Box>
                            <form className='w-full'>
                                <Text className='text-center text-2xl font-semibold '>ADMIN PANEL</Text>
                                <Box className='w-12/12 mx-8 grid grid-cols-1 gap-1 my-2'>
                                    <span className="block text-sm font-medium ">Usuario</span>
                                    <Input className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Usuario"
                                        value={formik.values.username}
                                        onChange={(e) => formik.setFieldValue('username', e.target.value.toLowerCase())}
                                    />
                                </Box>
                                <Box className='w-12/12 mx-8 grid-cols-1 gap-1 my-2'>
                                    <span className="block text-sm font-medium ">Contraseña</span>
                                    <Input className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="Ingresa tu contraseña"
                                        value={formik.values.password}
                                        onChange={(e) => formik.setFieldValue('password', e.target.value)}
                                    />

                                </Box>
                                <Box className='w-12/12 mx-8 mt-8'>
                                    <Button variant='solid' colorScheme='yellow' className='w-full  bold uppercase' onClick={()=> formik.handleSubmit() }>Iniciar sesión</Button>
                                </Box>
                                <Box className='w-12/12 mx-8 mb-8 text-center'>
                                    {/* <Link to={`/${SIGNIN}`} className='w-full lg:w-6/12  hover:text-blue-200 active:text-blue-400 py-1 px-2 rounded text-xs mx-1'>Crear cuenta</Link> */}
                                    {/* <Link to={`/${RESTORE_PASSWORD}`} className='w-full lg:w-6/12  hover:text-blue-200 active:text-blue-400 py-1 px-2 rounded text-xs  mx-1'>Recuperar contraseña</Link> */}
                                </Box>
                            </form>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login

function initialValues () {
    return {
        username: '',
        password: ''
    }
}

function validationSchema () {
    return {
        username: Yup.string().required(true),
        password: Yup.string()
            .min(1, 'La contraseña debe de ser Mayor o igual a 6 caracteres')
            .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
            .required(true)
    }
}
