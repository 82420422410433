import React, { useContext, useEffect, useState } from 'react'
import Logo from '../../assets/logo.png';
import AuthContext from '../../contexts/Auth/authContext';
import { Divider, Text, Tooltip, Button, Box } from '@chakra-ui/react'
import { HiUser, HiOutlineLogout,HiChevronDown, HiChevronUp, HiOutlineDocumentReport, HiOutlineUsers, HiOutlineMenu, HiOutlineIdentification, HiOutlineReceiptTax } from "react-icons/hi";
import { BsInboxes } from 'react-icons/bs'
import { MdOutlineDashboard } from 'react-icons/md'
import ToggleButton from '../ToggleButton';
import useWindowDimensions from '../../hooks/WindowsDImensions';
import MenuItem from './MenuItem';
const Sidebar = ({ showSidebar, show, showText, setShowText }) =>
{
  const { height, width } = useWindowDimensions();
  const authContext = useContext(AuthContext)
  const { logout, username } = authContext;
  const [ showVigencias, setShowVigencias ] = useState(false);

  useEffect(() =>
  {
    if (width <= 768) {
      setShowText(false)
    }
  }, [ width ])


  return (
    <>

      <Box className={ `h-[100vh]  ${ showText ? 'w-2/12 lg:w-44' : 'w-16 lg:w-16' }  z-40  ease-in-out duration-300 mr-5 md:mr-10 pr-0` }>


        <div className='flex flex-col items-stretch gap-2 px-1 md:mr-0 fixed  border-r h-[100vh] overflow-auto scrollbar-hide'>
          <div className='flex flex-col items-center p-2'>
            <div className={ `rounded-full bg-gray-100 border border-gray-800 ${ showText ? 'w-14' : 'w-10' }` }>
              <img className='w-full h-auto' src={ Logo } alt="logo" title="logo gc-com" />
            </div>

          </div>
          <div className=' px-5 grid grid-flow-col items-center justify-start pb-2'>
            <Tooltip label={ username ? `${ username.replaceAll('"', '') }` : null }>

              <div className='flex flex-row items-center'>
                <HiUser className='text-lg' />
                { showText && <h1 className='font-normal text-md mx-1'>  { username ? `${ username.replaceAll('"', '') }` : null } </h1> }

              </div>
            </Tooltip>
          </div>
          <Divider />

          <Box className="hidden md:block w-14   h-10 ">
            <Button variant='ghost' onClick={ () => { setShowText(!showText) } } className="hidden md:block w-14 hover:bg-gray-800" >
              <HiOutlineMenu className='hidden md:block' />
            </Button>
          </Box>
          <MenuItem showText={ showText } icon={ <MdOutlineDashboard className='text-md lg:text-lg' /> } path='/dashboard' text='Dashboard' />
          <Box variant='ghost' onClick={ () => { setShowVigencias(!showVigencias) } } className=' py-4 px-5 hover:bg-gray-800 hover:text-white hover:rounded-xl  grid grid-flow-col items-center justify-start'>
            <BsInboxes className='text-md lg:text-lg' /> { showText && <Text className='text-sm hidden md:block mx-1'>Vencimiento</Text> }
            {showVigencias ?   <HiChevronUp className='text-md lg:text-lg' />:   <HiChevronDown className='text-md lg:text-lg' />}
          </Box>
          <Box className={ `${ showVigencias ? 'block' : 'hidden'} border-l border-gray-50` }>
            <MenuItem showText={ showText } icon='10' path='/renovation/ten-days' text=' días' />
            <MenuItem showText={ showText } icon='30' path='/renovation/thirty-days' text=' días' />
          </Box>
          <MenuItem showText={ showText } icon={ <HiOutlineDocumentReport className='text-md lg:text-lg' /> } path='/plans' text='Planes' />
          <MenuItem showText={ showText } icon={ <HiOutlineReceiptTax className='text-md lg:text-lg' /> } path='/reports' text='Transacciones' />
          <MenuItem showText={ showText } icon={ <HiOutlineUsers className='text-md lg:text-lg' /> } path='/customers' text='Clientes' />
          <MenuItem showText={ showText } icon={ <HiOutlineIdentification className='text-md lg:text-lg' /> } path='/users' text='Usuarios' />
          {/* <NavLink to="/settings" className={({ isActive }) => (isActive ? ' text-white py-4 px-5 bg-gray-900 hover:bg-gray-800 hover:text-white rounded-xl  grid grid-flow-col items-center justify-start' :
            '  py-4 px-5 hover:bg-gray-800 hover:text-white hover:rounded-xl  grid grid-flow-col items-center justify-start')}>
            <HiOutlineCog className='text-md lg:text-lg' />
            <Text className='text-sm hidden md:block'>Configurar</Text>
          </NavLink> */}
          <Divider />
          <Text className='text-xs px-2'>Tema</Text>
          <ToggleButton showText={ showText } />
          <Divider />
          <div className={ ' py-4 px-5  hover:bg-gray-800 hover:text-white rounded-xl  grid grid-flow-col items-center justify-start' } onClick={ logout }>
            <HiOutlineLogout className='text-md lg:text-lg' />
            { showText && <Text className='text-sm hidden md:block'>Cerrar sesión</Text> }
          </div>

        </div>
      </Box >
    </>

  )
}

export default Sidebar
