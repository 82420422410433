import React from 'react'
import { HiOutlineTrash, HiOutlinePencilAlt, HiOutlineEye } from "react-icons/hi";
import dayjs from 'dayjs'
import 'dayjs/locale/es'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import CustomerRow from './CustomerRow';

const CustomersTable = ({ items, onOpen, remove }) => {
  return (
    <TableContainer className='w-full'>
      <Table variant='striped'>
        <TableCaption></TableCaption>
        <Thead>
          <Tr className=' rounded-lg '>

            <Th># Cuenta</Th>
            <Th align="left">Nombre</Th>
            <Th align="center">Fecha de corte</Th>
            {/* <Th align="center">Fecha de Corte</Th> */}
            <Th align="center">Fecha de Limite</Th>

            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items && items.map((item) => (
         <CustomerRow item={item} onOpen={onOpen} remove={remove} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default CustomersTable