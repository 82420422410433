/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import Sidebar from '../components/Sidebar/Sidebar';
// import Header from '../components/Header/header';
import AuthContext from '../contexts/Auth/authContext';
import { LOGIN, INDEX } from './types';

const PrivateRoute = ({ children }) => {
    const authContext = useContext(AuthContext);
    const { token, username } = authContext;
    const [showText, setShowText] = useState(true);
    const [showSidebar, setShowSidebar] = useState(false);
    // const [showChangePassword, setShowChangePassword] = useState(false);
    // const userInfo = JSON.parse(localStorage.getItem('user'))
    const show = () => {
        setShowSidebar(!showSidebar)
    }


    return token ? <div className="flex flex-col h-[100-vh] overflow-hidden w-full">
        {/* <Header show={show} showSidebar={showSidebar}   setShowChangePassword={setShowChangePassword}/> */}

        <div className='flex flex-row gap-1 overflow-y-hidden'>
            <Sidebar showSidebar={showSidebar} show={show} showText={showText} setShowText={setShowText} />


            {children}
        </div>
    </div>

        : <Navigate to={'/login'} replace />
}

export default PrivateRoute
