import React from 'react'
import { HiOutlineTrash,HiOutlinePencilAlt } from "react-icons/hi";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tooltip,
  Wrap,
  WrapItem,
  IconButton
} from '@chakra-ui/react'

const PlansTable = ({ items, onOpen, remove }) => {
  return (
    <TableContainer className='w-full'>
      <Table variant='striped' >
        <TableCaption></TableCaption>
        <Thead>
          <Tr className=' rounded-lg '>
            <Th className=' py-4'>Nombre del plan</Th>
            <Th className='' align="left">Descripción</Th>
            <Th className='' align="center">Megas</Th>
            <Th className='' align="right">Precio</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items && items.map((item) => (
            <Tr className='py-4 px-5 '
              key={item._id}
            >
              <Td className='py-4 px-5'>
                {item.name}
              </Td>
              <Td align="left">{item?.description ? item.description : 'N/A'}</Td>
              <Td align="center">{item?.megas ? item.megas : 'N/A'} MB</Td>
              <Td isNumeric>$ {item.price}</Td>
              <Td className='relative'>
                <Wrap>
                  <WrapItem> 
                    <Tooltip label='Editar plan' fontSize='md'>
                      <IconButton onClick={()=>onOpen(item)} variant='ghost' colorScheme="grar.600" size="sm" aria-label='Editar plan' icon={<HiOutlinePencilAlt className='text-md lg:text-lg' />} />

                    </Tooltip>
                  </WrapItem>
                  <WrapItem>
                    <Tooltip label='Borrar Plan' fontSize='md'>
                      <IconButton onClick={()=>remove({_id:item._id})} variant='ghost' colorScheme="grar.600" size="sm" aria-label='Borrar Plan' icon={<HiOutlineTrash className='text-md lg:text-lg' />} />

                    </Tooltip>
                  </WrapItem>
                </Wrap>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default PlansTable