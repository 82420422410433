import React, { useState, useEffect, useContext } from 'react'
import md5 from 'md5'
import { HiEye, HiEyeOff } from "react-icons/hi";
import {
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Button
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import UserContext from '../../contexts/Users/userContext';



const UserModal = ({ onOpen, onClose, isOpen, item }) => {
  const userContext = useContext(UserContext);
  const { postNewUser, updateUser } = userContext;
  const [loading, setLoading] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      setLoading(true)
      data.password = md5(data.password);
      data.repeat_password = md5(data.repeat_password);
      delete data.repeat_password
      // if (data.status === 'true') {
      //   data.status = true
      // } else {
      //   data.status = false
      // }
      try {
        if (!item) {
          await postNewUser(data);
        } else {
          data._id = item._id;
          await updateUser(data);
        }
        onClose()
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
  })
  const close = () => {
    formik.resetForm()
    onClose()
  }
  useEffect(() => {
    console.log(item)
    if (item) {
      formik.setFieldValue('username', item.username);
      formik.setFieldValue('name', item.name);
      formik.setFieldValue('email', item.email);
      formik.setFieldValue('role', item.role);
      formik.setFieldValue('status', item.status);

    }
  }, [item])

  return (
    <>

      <Modal closeOnOverlayClick={false} size='xl' blockScrollOnMount={false} isOpen={isOpen} onClose={close} isCentered>
        <ModalOverlay bg='blackAlpha.800' />
        <ModalContent >
          <ModalHeader >{!item?._id ? 'Agregar Usuario' : 'Editar Usuario'}</ModalHeader>
          <ModalCloseButton  />
          <ModalBody>
            <FormControl>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='name' >Nombre</FormLabel>
                  <InputGroup >
                    <Input type='text' placeholder='Nombre' 
                      value={formik.values.name}
                      isInvalid={formik.touched.name && formik.errors.name}
                      isRequired
                      onChange={(e) => formik.setFieldValue('name', e.target.value)}
                    />

                  </InputGroup>
                  {formik.touched.name && formik.errors.name && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.name}</FormHelperText>}
                </Stack>


              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='email' >Email</FormLabel>
                  <InputGroup >
                    <Input type='text' placeholder='test@test.com' 
                      value={formik.values.email}
                      isInvalid={formik.touched.email && formik.errors.email}
                      isRequired
                      onChange={(e) => formik.setFieldValue('email', e.target.value)}
                    />

                  </InputGroup>
                  {formik.touched.email && formik.errors.email && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.email}</FormHelperText>}
                </Stack>

              </div>

              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='username' >Usuario</FormLabel>
                  <InputGroup >
                    <Input type='text' placeholder='username' 
                      value={formik.values.username}
                      isInvalid={formik.touched.username && formik.errors.username}
                      isRequired
                      onChange={(e) => formik.setFieldValue('username', e.target.value)}
                    />

                  </InputGroup>
                  {formik.touched.username && formik.errors.username && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.username}</FormHelperText>}
                </Stack>

              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='role' >Role</FormLabel>
                  <InputGroup >

                    <Select placeholder='Selecciona un rol'
                      value={formik.values.role}
                      isInvalid={formik.touched.role && formik.errors.role}
                      isRequired
                      
                      onChange={(e) => formik.setFieldValue('role', e.target.value)}
                    >
                      <option value='SUPER_ADMIN'>Super admin</option>
                      <option value='ADMIN'>Admin</option>
                      <option value='USER'>User</option>
                    </Select>

                  </InputGroup>
                  {formik.touched.role && formik.errors.role && <FormHelperText color='red.400' classusername="text-red-600 text-xs">{formik.errors.role}</FormHelperText>}
                </Stack>
                <Stack className='w-full'>
                  <FormLabel htmlFor='status' >Estado</FormLabel>
                  <InputGroup >

                    <Select placeholder='Selecciona el estatus'
                      value={formik.values.status}
                      isInvalid={formik.touched.status && formik.errors.status}
                      isRequired
                      
                      onChange={(e) => formik.setFieldValue('status', e.target.value)}
                    >
                      <option value={true}>Activo</option>
                      <option value={false}>Inactivo</option>
                    </Select>

                  </InputGroup>
                  {formik.touched.status && formik.errors.status && <FormHelperText color='red.400' classusername="text-red-600 text-xs">{formik.errors.status}</FormHelperText>}
                </Stack>

              </div>
              <div className='flex flex-col md:flex-row  items-center justify-between gap-1 pt-4'>
                <Stack className='w-full'>
                  <FormLabel htmlFor='password' >Contraseña</FormLabel>
                  <InputGroup >
                    <Input placeholder='' 
                      value={formik.values.password}
                      isInvalid={formik.touched.password && formik.errors.password}
                      isRequired
                      type={showPass ? 'text' : 'password'}
                      onChange={(e) => formik.setFieldValue('password', e.target.value)}
                    />
                    <InputRightAddon onClick={() => setShowPass(!showPass)} children={!showPass ? <HiEye /> : <HiEyeOff />} />
                  </InputGroup>
                  {formik.touched.password && formik.errors.password && <FormHelperText color='red.400' classusername="text-red-600 text-xs">{formik.errors.password}</FormHelperText>}
                </Stack>
                <Stack className='w-full'>
                  <FormLabel htmlFor='repeat_password' >Repetir contraseña</FormLabel>
                  <InputGroup >
                    <Input placeholder='' 
                      value={formik.values.repeat_password}
                      isInvalid={formik.touched.repeat_password && formik.errors.repeat_password}
                      isRequired
                      type={showPass ? 'text' : 'password'}
                      onChange={(e) => formik.setFieldValue('repeat_password', e.target.value)}
                    />
                    <InputRightAddon onClick={() => setShowPass(!showPass)} children={!showPass ? <HiEye /> : <HiEyeOff />} />
                  </InputGroup>
                  {formik.touched.repeat_password && formik.errors.repeat_password && <FormHelperText color='red.400' classusername="text-red-600 text-xs">{formik.errors.repeat_password}</FormHelperText>}
                </Stack>

              </div>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost'  mr={3} onClick={close}>
              Cerrar
            </Button>
            <Button colorScheme='yellow' onClick={() => formik.handleSubmit()}> {!item?._id ? 'Agregar' : 'Actualizar'}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserModal;

function initialValues () {
  return {
    name: '',
    email: '',
    username: '',
    password: '',
    repeat_password: '',
    status: true,
    role: ''


  }
}

function validationSchema () {
  return {
    name: Yup.string().required('El nombre es requerido'),
    email: Yup.string().required('El correo es requerido'),
    username: Yup.string().required('El usuario es requerido'),
    role: Yup.string().required('El rol es requerido'),
    status: Yup.boolean().oneOf([true, false]).required('El estado es requerido'),
    password: Yup.string().required('La contraseña debe de ser mayor a 10 caracteres'),
    repeat_password: Yup.string().required('El password no es igual'),
  }
}