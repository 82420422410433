import React from 'react';
import { MdOutlineAccountCircle, MdOutlineMail, MdOutlinePhone, MdOutlinePhoneIphone, MdExpandMore, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import
  {
    Tag,
    Text,
    Box
  } from '@chakra-ui/react'

const RowRenovation = ({ item, renovar, today, tenDays }) =>
{
  const hoy = Date.parse(today);
  const downDate = Date.parse(today);
  const warningDate = Date.parse(tenDays);
  const limit_date_billing = Date.parse(dayjs(item?.limit_date_billing).format('YYYY-MM-DD'))

  return (
    <Box className='border-b last:border-non px-2 my-2 grid grid-cols-3 gap-2 py-4 place-items-center'>
      <Box>
        { hoy === limit_date_billing && <Tag className='p-1 text-xs text-center' colorScheme='orange'>Hoy Vence</Tag> }
        { hoy < limit_date_billing && <Tag className='p-1 text-xs text-center' colorScheme='yellow'>Vencera pronto</Tag> }
      </Box>
      <Box>
        <Text>{dayjs(item?.limit_date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase()}</Text>
      </Box>
      <Box>
        <Text>   {item.num_account}</Text>
      </Box>

    </Box>
  )
}

export default RowRenovation
