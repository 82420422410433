import React from 'react'
import { useColorMode } from '@chakra-ui/color-mode'
import { IconButton, Box, Text, Tooltip, Button } from '@chakra-ui/react'
import { HiMoon, HiSun } from 'react-icons/hi'

const ToggleButton = ({ showText }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Tooltip label={!showText ? colorMode === 'light' ? 'Light' : 'Dark' : null}>
      {showText ?
        <Box className='flex flex-row items-center mx-2' onClick={toggleColorMode}>
          <Button className=" hover:bg-gray-800 " variant='ghost' leftIcon={colorMode === 'light' ? <HiMoon /> : <HiSun />}>
            {colorMode === 'light' ? 'Light' : 'Dark'}
          </Button>
          {/* <IconButton className="w-14 hover:bg-gray-800 " variant='ghost'  icon={colorMode === 'light' ? <HiMoon /> : <HiSun />} />
        {showText && <Text>{colorMode === 'light' ? 'Light' : 'Dark'}</Text>} */}

        </Box>
        :
        <Box className='flex flex-row items-center'>
          <IconButton className="w-14 hover:bg-gray-800 " variant='ghost' onClick={toggleColorMode} icon={colorMode === 'light' ? <HiMoon /> : <HiSun />} />
          {showText && <Text>{colorMode === 'light' ? 'Light' : 'Dark'}</Text>}
        </Box>
      }
    </Tooltip>

  )
}

export default ToggleButton

