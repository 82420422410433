import React, { useState } from 'react'
import {
  Tr,
  Td,
  Tooltip,
  Wrap,
  WrapItem,
  IconButton,
  Box,
  Text
} from '@chakra-ui/react'
import { HiOutlineTrash, HiOutlinePencilAlt, HiOutlineEye } from "react-icons/hi";
import { MdOutlineAccountCircle, MdOutlineMail, MdOutlinePhone, MdOutlinePhoneIphone, MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import dayjs from 'dayjs'
import 'dayjs/locale/es'

const CustomerRow = ({ item, onOpen, remove }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <Tr className=' py-4 px-5 '
        key={item._id}
      >
        <Td className=' py-4 px-5'>
          {item.num_account}
        </Td>
        <Td align="left">{item?.full_name ? item.full_name : 'N/A'}</Td>

        <Td>
          {dayjs(item?.date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase()}
        </Td>
        <Td>
          {dayjs(item?.limit_date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase()}
        </Td>
        <Td className='relative'>
          <Wrap>

            <WrapItem>
              <Tooltip label='Editar Cliente' fontSize='md'>
                <IconButton onClick={() => onOpen(item, true)} variant='ghost' colorScheme="grar.600" size="sm" aria-label='Editar cliente' icon={<HiOutlinePencilAlt className='text-md lg:text-lg' />} />
              </Tooltip>
            </WrapItem>
            <WrapItem>
              <Tooltip label='Borrar Cliente' fontSize='md'>
                <IconButton onClick={() => remove({ _id: item._id })} variant='ghost' colorScheme="grar.600" size="sm" aria-label='Borrar cliente' icon={<HiOutlineTrash className='text-md lg:text-lg' />} />

              </Tooltip>
            </WrapItem>
            <WrapItem>
              <Tooltip label='Ver Cliente' fontSize='md'>
                <IconButton onClick={() => setShow(!show)} variant='ghost' colorScheme="grar.600" size="sm" aria-label='Ver cliente' icon={show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />} />
              </Tooltip>
            </WrapItem>
          </Wrap>
        </Td>
      </Tr>
      {show && <Tr>
        <Td colSpan={5}>
          <Box className='grid grid-cols-2 gap-2 mb-2'>
            <Text className='mt-0 pt-0 text-sm font-semibold'>Información de la cuenta</Text>
            <Text className='mt-0 pt-0 text-sm font-light text-right'>Cliente desde: {dayjs(item?.start_from).locale('es').format('DD/MMM/YYYY').toUpperCase()}</Text>
          </Box>
          <Box>

            <Box className='flex flex-row gap-1 justify-between'>
              <Box className='grid grid-cols-2'>
                <div className='flex flex-row items-center  '><MdOutlineAccountCircle className='mr-1' />{item?.full_name ? item.full_name : 'N/A'}</div>
                <div className='flex flex-row items-center  '><MdOutlineMail className='mr-1' /> {item?.email ? item.email : 'N/A'}</div>
                <div className='flex flex-row items-center  '> <MdOutlinePhone className='mr-1' />{item?.phone ? item.phone : 'N/A'}</div>
                <div className='flex flex-row items-center  '> <MdOutlinePhoneIphone className='mr-1' />{item?.cellphone ? item.cellphone : 'N/A'}</div>
              </Box>
              <Box>
                <div className=' '>Tipo: {item?.type_contract}</div>
                <div className=' '>Plan: {item?.name_type_plan}</div>
                <div className=' '>Días Limite: {item?.limit_payday}</div>
              </Box>
            </Box>
          </Box>
        </Td>
      </Tr>}
    </>
  )
}

export default CustomerRow