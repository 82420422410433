import React from 'react'
import { MdOutlineAccountCircle, MdOutlineMail, MdOutlinePhone, MdOutlinePhoneIphone, MdExpandMore } from "react-icons/md";

import RowHome from './RowHome';
import { renovation_customer } from '../../utils/apis/home';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tag
} from '@chakra-ui/react'



const RenovationTable = ({ items, today, tenDays, openModal }) => {

  const renovar = async (item, type_renovation, number_renovation, amount, comments) => {
    const data = {
      _id: item._id,
      num_account: item.num_account,
      type_renovation,
      number_renovation,
      amount,
      comments
    }
     await renovation_customer(data);
  }



  return (

    <TableContainer className='w-full'>
      <Table variant='striped' size='lg' className='w-full'>
        <TableCaption ><h4 className='text-lg font-thin mb-2'>Cuentas proximas a vencer </h4></TableCaption>
        <Thead>
          <Tr className=' rounded-lg '>
            <Th align="center">Estado</Th>
            <Th align="center">Fechas de corte</Th>
            <Th align="center">Fechas de limite</Th>
            <Th># Cuenta</Th>
            <Th align='center'>Acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items && items.map((item) => (
          <RowHome key={item._id} openModal={openModal} item={item} renovar={renovar} today={today} tenDays={tenDays}/>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default RenovationTable