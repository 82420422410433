import React, { useEffect, useState, useContext } from 'react'
import {
  Button,
  Stack,
  Skeleton,
  Spinner
} from '@chakra-ui/react'

import PlansTable from '../components/Plans/Table'
import ModalPlans from '../components/Plans/Modal'
import PlanContext from '../contexts/Plans/planContext'

const Plans = () => {
  const planContext = useContext(PlanContext);
  const { list, loading, getPlanList, deletePlan } = planContext;
  const [item, setItem] = useState(null)
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      await getPlanList();
    })()
  }, [])
  useEffect(() => {
    (async () => {
      await getPlanList();
    })()
  }, [loading])

  const onOpen = (item) => {
    setIsOpen(true);
    setItem(item);
  }
  const onClose = () => {
    setIsOpen(false);
    setItem(null);
  }

    return (
      <>
        <div className='w-full gap-1 h-[100vh] overflow-x-auto py-2 px-2'>
          <div className='mx-2'>
            <div className='flex flex-row justify-between  my-3 '>
              <h1 className='text-4xl font-semibold'>Planes </h1>
              <Button colorScheme='yellow' onClick={() => onOpen(null)}>Agregar plan</Button>

            </div>
            <div className='py-1 rounded flex flex-col items-center justify-center'>
              {list !== null ?
                <PlansTable items={list} onOpen={onOpen} remove={deletePlan} />
                :
                <div className='my-4'>
                  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <ModalPlans isOpen={isOpen} onOpen={onOpen} onClose={onClose} item={item} />
      </>
    )
  

}

export default Plans