import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router'
import {
  Button,
  Stack,
  Skeleton,
  Spinner
} from '@chakra-ui/react'

import UsersTable from '../components/Users/UsersTable'
import UserContext from '../contexts/Users/userContext'
import UserModal from '../components/Users/Modal'

const Users = () => {
  const navigate = useNavigate()
  const userContext = useContext(UserContext);
  const { list, loading, getUsersList, deleteUser, errorAuth} = userContext;
  const [item, setItem] = useState(null)
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    (async () => {
      await getUsersList();
    })()
  }, [])
  useEffect(() => {
    (async () => {
      await getUsersList();
    })()
  }, [loading])

  // useEffect(() => {
  //   (async () => {
  //     navigate('/')
  //   })()
  // }, [errorAuth])

  const onOpen = (item) => {
    setIsOpen(true);
    setItem(item);
  }
  const onClose = () => {
    setIsOpen(false);
    setItem(null);
  }

  return (
    <>
    <div className=' w-full gap-1 h-[100vh] overflow-x-auto pt-2 px-2'>
      <div className='mx-2'>
        <div className='flex flex-row justify-between  my-3 '>
          <h1 className='text-4xl font-semibold'>Usuarios </h1>
          <Button colorScheme='yellow' onClick={() => onOpen(null)}>Agregar usuarios</Button>

        </div>
        <div className='py-1 rounded flex flex-col items-center justify-center overflow-auto'>
          {list !== null ?
            <UsersTable items={list} onOpen={onOpen} remove={deleteUser} />
            :
            <div className='my-4'>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='yellow.400'
                size='xl'
              />
            </div>
          }
        </div>
      </div>
    </div>
    <UserModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} item={item} />
  </>
  )
}

export default Users