import React, { useState, useEffect, useContext } from 'react'
import { HiCheck, HiX } from "react-icons/hi";
import {
  Stack,
  Select,
  Text,
  Tag,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  Button
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs';
import { renovation_customer } from '../../utils/apis/home'
import { ListItemButton } from '@mui/material';


const ModalRenovation = ({ onOpen, onClose, isOpen, item }) => {
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      setLoading(true)
      try {
        const record = {
          _id: item._id,
          num_account: data.num_account,
          comments: data.comments,
          date: dayjs(data.date).format('DD/MM/YYYY'),
          amount: data.amount,
          number_renovation: Number(data.numero),
          type_renovation: data.type_renovation
        }
        await renovation_customer(record);

        onClose(true)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
  })
  const close = () => {
    formik.resetForm()
    onClose(null)
  }
  useEffect(() => {
    console.log(ListItemButton)
    if (item) {
      formik.setFieldValue('num_account', item.num_account);
      if (item.type_contract === 'Postpago') {
        formik.setFieldValue('date', dayjs(item.date_billing).format('YYYY-MM-DD'));

      }
      formik.setFieldValue('numero', 1);
      formik.setFieldValue('type_renovation', 'MONTH');
      formik.setFieldValue('amount', item.price_type_plan);
    }
  }, [item])

  return (
    <>

      <Modal closeOnOverlayClick={false} size='xl' blockScrollOnMount={false} isOpen={isOpen} onClose={close} isCentered>
        <ModalOverlay bg='blackAlpha.800' />
        <ModalContent >
          <ModalHeader>
            <Box>
              <Text className='text-center uppercase mb-2'>Renovaciones</Text>
              <Text className='text-md font-light'>Cuenta: {item?.num_account}</Text>
              <Text className='text-sm font-light'>{item?.type_contract}</Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Stack spacing={4}>
                <FormLabel htmlFor='num_account' >Numero de Cuenta</FormLabel>
                <InputGroup >
                  <Input type='text' placeholder='Nombre'
                    value={formik.values.num_account}
                    isInvalid={formik.touched.num_account && formik.errors.num_account}
                    isRequired
                    onChange={(e) => formik.setFieldValue('num_account', e.target.value)}
                  />

                </InputGroup>
                {formik.touched.num_account && formik.errors.num_account && <FormHelperText color='red.400' classnum_account="text-red-600 text-xs">{formik.errors.num_account}</FormHelperText>}


                <div className='grid grid-cols-2 gap-1 items-center justify-between'>
                  <div>
                    <FormLabel htmlFor='date' >Fecha de inicio de servicio</FormLabel>


                    <Input type='date' placeholder='20'
                      value={formik.values.date}
                      isInvalid={formik.touched.date && formik.errors.date}
                      isRequired
                      onChange={(e) => formik.setFieldValue('date', e.target.value)}
                    />
                    {formik.touched.date && formik.errors.date && <FormHelperText color='red.400' classnum_account="text-red-600 text-xs">{formik.errors.date}</FormHelperText>}
                  </div>
                  <div>
                    <FormLabel htmlFor='price'  >Monto</FormLabel>
                    <InputGroup >
                      <InputLeftElement
                        pointerEvents='none'
                        fontSize='1.2em'
                        children='$'
                      />
                      <Input type='number' placeholder='20'
                        value={formik.values.amount}
                        isInvalid={formik.touched.amount && formik.errors.amount}
                        isRequired
                        onChange={(e) => formik.setFieldValue('amount', e.target.value)}
                      />

                    </InputGroup>
                    {formik.touched.amount && formik.errors.amount && <FormHelperText color='red.400' classnum_account="text-red-600 text-xs">{formik.errors.amount}</FormHelperText>}
                  </div>
                </div>
                <Text >Periodo de renovación</Text>
                <Box className='grid grid-cols-2 gap-2 items-center justify-between'>
                  <Select value={formik.values.numero} onChange={(e) => formik.setFieldValue('numero', e.target.value)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="15">15</option>
                  </Select>
                  <Box className='grid grid-cols-3 gap-1'>
                    <Tag size='lg' colorScheme={formik.values.type_renovation === 'DAY' ? 'yellow' : 'gray'} variant={formik.values.type_renovation === 'DAY' ? 'solid' : 'outline'} onClick={() => formik.setFieldValue('type_renovation', 'DAY')}>Día(s)</Tag>
                    <Tag size='lg' colorScheme={formik.values.type_renovation === 'MONTH' ? 'yellow' : 'gray'} variant={formik.values.type_renovation === 'MONTH' ? 'solid' : 'outline'} onClick={() => formik.setFieldValue('type_renovation', 'MONTH')}>Mes(es)</Tag>
                    <Tag size='lg' colorScheme={formik.values.type_renovation === 'YEAR' ? 'yellow' : 'gray'} variant={formik.values.type_renovation === 'YEAR' ? 'solid' : 'outline'} onClick={() => formik.setFieldValue('type_renovation', 'YEAR')}>Año(s)</Tag>
                  </Box>
                </Box>
                <Box>
                  <FormLabel htmlFor='comments' >Comentarios</FormLabel>
                  <Textarea placeholder=''
                    value={formik.values.comments}
                    isInvalid={formik.touched.comments && formik.errors.comments}
                    isRequired
                    onChange={(e) => formik.setFieldValue('comments', e.target.value)}
                  />
                  {formik.touched.comments && formik.errors.comments && <FormHelperText color='red.400' classnum_account="text-red-600 text-xs">{formik.errors.comments}</FormHelperText>}
                </Box>
              </Stack>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={close}>
              Cerrar
            </Button>
            <Button colorScheme='yellow' onClick={() => formik.handleSubmit()}>Renovar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalRenovation;

function initialValues () {
  return {
    num_account: '',
    comments: '',
    date: '',
    amount: '',
    numero: '',
    type_renovation: ''

  }
}

function validationSchema () {
  return {
    num_account: Yup.string().required('El nombre es requerido'),
    comments: Yup.string().required('Es necesaría una descripción'),
    date: Yup.string().required('La fecha es necesaria'),
    amount: Yup.number().required('Ingresa el numero de amount'),
    numero: Yup.number().required('Es numero de renomación'),
    type_renovation: Yup.string().required('El type_renovation'),
  }
}
