import React from 'react'
import { HiOutlineTrash, HiOutlinePencilAlt, HiOutlineEye, HiCircle } from "react-icons/hi";
import {MdCircle} from "react-icons/md"
import dayjs from 'dayjs'
import 'dayjs/locale/es'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tooltip,
  Wrap,
  WrapItem,
  IconButton
} from '@chakra-ui/react'

const UsersTable = ({ items, onOpen, remove }) => {
  return (
    <TableContainer className='w-full'>
      <Table variant='striped'>
        <TableCaption></TableCaption>
        <Thead>
          <Tr className=' rounded-lg '>
            <Th align="left">Usuario</Th>
            <Th align="left">Nombre</Th>
            <Th align="center">Email</Th>
            <Th align="center">Estado</Th>
            <Th align="center">Role</Th>

            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items && items.map((item) => (
            <Tr className=' py-4 px-5 '
              key={item._id}
            >
              <Td className=' py-4 px-5'>
                {item.username}
              </Td>
              <Td className=' py-4 px-5'>
                {item.name}
              </Td>
              <Td align="center">{item?.email ? item.email : 'N/A'}</Td>
              <Td>
                {item?.status ? <MdCircle className='text-md lg:text-lg text-green-500' /> : <MdCircle className='text-md lg:text-lg text-red-500' /> }
              </Td>
              <Td>
                {item?.role}
              </Td>
              <Td className='relative'>
                <Wrap>
                <WrapItem>
                    <Tooltip label='Ver Cliente' fontSize='md'>
                      <IconButton onClick={() => onOpen(item)} variant='ghost' colorScheme="grar.600" size="sm" aria-label='Ver cliente' icon={<HiOutlineEye className='text-md lg:text-lg' />} />
                    </Tooltip>
                  </WrapItem>
                  <WrapItem>
                    <Tooltip label='Editar Cliente' fontSize='md'>
                      <IconButton onClick={() => onOpen(item)} variant='ghost' colorScheme="grar.600" size="sm" aria-label='Editar cliente' icon={<HiOutlinePencilAlt className='text-md lg:text-lg' />} />
                    </Tooltip>
                  </WrapItem>
                  <WrapItem>
                    <Tooltip label='Borrar Cliente' fontSize='md'>
                      <IconButton onClick={() => remove({ _id: item._id })} variant='ghost' colorScheme="grar.600" size="sm" aria-label='Borrar cliente' icon={<HiOutlineTrash className='text-md lg:text-lg' />} />

                    </Tooltip>
                  </WrapItem>
                </Wrap>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default UsersTable