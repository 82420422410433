import React from 'react'
import { NavLink } from 'react-router-dom';
import { Text, Tooltip } from '@chakra-ui/react';

const MenuItem = ({ showText, icon, path, text }) => {
  return (
    <Tooltip label={!showText ? text : null}>
      <NavLink to={path} className={({ isActive }) => (isActive ? ' text-white py-4 px-5 bg-gray-900 hover:bg-gray-800 hover:text-white rounded-xl  grid grid-flow-col items-center justify-start' :
        '  py-4 px-5 hover:bg-gray-800 hover:text-white hover:rounded-xl  grid grid-flow-col items-center justify-start')}>
        {icon ? icon : null}
        {showText && <Text className='text-sm hidden md:block mx-1'>{text}</Text>}
      </NavLink>
    </Tooltip>
  )
}

export default MenuItem
