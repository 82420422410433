import React, { useEffect, useState, useContext } from 'react'
import {
  Button,
  Stack,
  Skeleton,
  Box,
  Input,
  Spinner
} from '@chakra-ui/react'

import CustomersTable from '../components/Customers/CustomersTable'
import CustomerContext from '../contexts/Customers/customerContext'
import ModalCustomers from '../components/Customers/Modal'

const Customers = () => {
  const customerContext = useContext(CustomerContext);
  const { list, loading, getCustomersList, deleteCustomer } = customerContext;
  const [searchText, setSearchText] = useState('')
  const [items, setItems] = useState(null);
  const [item, setItem] = useState(null)
  const [isEditable, setIsEditable] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    (async () => {
      await getCustomersList();
    })()
  }, [])
  useEffect(() => {
    (async () => {
      await getCustomersList();
    })()
  }, [loading]);

  useEffect(() => {
    (async () => {
      setItems(list)
    })()


  }, [list])

  const onOpen = (item, edit) => {
    setIsOpen(true);
    setItem(item);
    setIsEditable(edit)
  }
  const onClose = () => {
    setIsOpen(false);
    setItem(null);
  }

  const searchBd = (texto) => {
    setSearchText(texto);
    const originalList = list

    const filters = searchText.split(' ')
    let resultFilter = originalList
    for (let fl of filters) {
      resultFilter = resultFilter.filter(item => {
        const itemData = `${item.full_name.toUpperCase()}  ${item.email.toUpperCase()}  ${item.num_account.toUpperCase()}`;
        const textData = fl.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });

    }
    setItems(resultFilter)
  }

  return (
    <>
      <div className='w-full gap-1 h-[100vh] overflow-x-auto pt-2 px-2'>
        <div className='mx-2'>
          <div className='flex flex-row justify-between  my-3 '>
            <h1 className='text-4xl font-semibold'>Clientes </h1>
            <Button colorScheme='yellow' onClick={() => onOpen(null, true)}>Agregar cliente</Button>

          </div>
          <div className='flex flex-row justify-end  my-3 '>

            <Box>
              <Input placeholder='Buscador' value={searchText} onChange={(e) => searchBd(e.target.value)} />
            </Box>

          </div>
          <div className=' py-1 rounded flex flex-col items-center justify-center overflow-auto'>
            {list !== null ?
              <CustomersTable items={items} onOpen={onOpen} remove={deleteCustomer} />
              :
              <div className='my-4'>
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
              </div>
            }
          </div>
        </div>
      </div>
      <ModalCustomers isOpen={isOpen} onOpen={onOpen} onClose={onClose} item={item} edit={isEditable} />
    </>
  )
}

export default Customers