import React, { useState, useEffect } from 'react'
import { Box, Text, Input, IconButton, Tooltip } from '@chakra-ui/react';
import { currencyFormat } from 'simple-currency-format';
import { HiSearch } from 'react-icons/hi'
import { get_records } from '../utils/apis/records';
import { Spinner } from '@chakra-ui/react';
import dayjs from 'dayjs';
import Record from '../components/Records/Record';
import { getTempTotal } from '../utils/functions/getTotal';

const Records = () => {
  const [items, setItems] = useState();
  const [startDate, setStartDate] = useState();
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [endDate, setEndDate] = useState();
  const [typeContract, setTypeContract] = useState("Postpago");

  useEffect(() => {
    setStartDate(dayjs().startOf('month').format('YYYY-MM-DD'));
    setEndDate(dayjs().endOf('month').format('YYYY-MM-DD'))
    getRecords(dayjs().startOf('month'), dayjs().endOf('month'))
  }, []);

  const getRecords = async (startDate, endDate) => {
    const data = {
      filter: [dayjs(startDate).format('DD/MM/YYYY'), dayjs(endDate).format('DD/MM/YYYY'),]
    }
    const res = await get_records(data);
    setItems(res.records.reverse())
    setTotal(res.total_renovacion)
    setTotalAmount(getTempTotal(res.records))
  }

  const onSearch = async () => {
    await getRecords(startDate, endDate);
  }

  return (
    <div className='w-full gap-1 h-[100vh] overflow-x-auto py-2 px-2'>
      <div className='mx-2'>
        <div className='flex flex-col md:flex-row  justify-between  my-3'>
          <Box className='flex flex-col justify-between'>
            <Text className='text-4xl font-semibold'>Registro de ingresos</Text>
            <Text className='my-2'>Total de pagos registrados: {total}</Text>
          </Box>
          <Box className='flex flex-col md:flex-row justify-between gap-2 items-stretch md:items-center'>
            <Box className='flex flex-row items-center gap-1'>
              <Text>De:</Text>
              <Input value={startDate} type='date' onChange={(e) => setStartDate(e.target.value)} />
            </Box>
            <Box className='flex flex-row items-center gap-1'>
              <Text>A:</Text>
              <Input value={endDate} type='date' onChange={(e) => setEndDate(e.target.value)} />
            </Box>
            <Tooltip label='Buscar'>
              <IconButton icon={<HiSearch />} onClick={() => onSearch()} />
            </Tooltip>
          </Box>



        </div>
        <Box className='flex flex-row justify-end items-end gap-2'>
          <Text className='font-light text-xl'>Total:</Text>
          <Text className='font-semibold text-2xl uppercase'>{currencyFormat(totalAmount, 'es-MX', 'MXN', 2)}</Text>
          <Text className='font-light text-xl'>MXN</Text>
        </Box>
        <div className='py-1 rounded flex flex-col items-center justify-center overflow-auto mt-4'>
          {items ? items.map(item => (
            <Record key={item._id} item={item} />
          ))

            :
            <div className='my-4'>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Records
