import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import Loading from '../Loading/Loading'

const BoxSummary = ({ title, value, color }) =>
{
  return (
    <Box w='100%' px={ 4 } mt={ 2 } className='pb-4' borderWidth='1px' borderRadius='lg' bg={ `${ color }.100` }>
      <Text className='font-bold uppercase text-xs md:text-sm mt-2' noOfLines={ 1 } color={ `${ color }.700` }>{ title }</Text>
      { value ?
      <Text className='font-bold text-lg md:text-2xl text-right' color={ `${ color }.700` } >{ value }</Text>
      :
      <Loading /> }
    </Box>
  )
}

export default BoxSummary
