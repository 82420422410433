import React, { useState, useEffect, useContext } from 'react'
import { HiCheck, HiX } from "react-icons/hi";
import {
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  InputRightAddon,
  Textarea,
  Button
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PlanContext from '../../contexts/Plans/planContext';



const ModalPlans = ({ onOpen, onClose, isOpen, item }) => {
  const planContext = useContext(PlanContext);
  const {postNewPlan, updatePlan} = planContext;
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      setLoading(true)
      try {

        if(item === null){
          await postNewPlan(data);
          formik.resetForm()
        }else{
          data._id = item._id;
          await updatePlan(data)
          formik.resetForm()
        }
        onClose()
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
  })
  const close = () => {
    formik.resetForm()
    onClose()
  }
  useEffect(() => {
    if(item){
      formik.setFieldValue('name', item.name);
      formik.setFieldValue('description', item.description);
      formik.setFieldValue('megas', item.megas);
      formik.setFieldValue('price', item.price);
    }
  }, [item])
  
  return (
    <>

      <Modal closeOnOverlayClick={false} size='xl' blockScrollOnMount={false} isOpen={isOpen} onClose={close} isCentered>
        <ModalOverlay bg='blackAlpha.800' />
        <ModalContent >
          <ModalHeader >{!item?._id ? 'Agregar Plan':'Editar Plan'}</ModalHeader>
          <ModalCloseButton  />
          <ModalBody>
            <FormControl>
              <Stack spacing={4}>
                <FormLabel htmlFor='name' >Nombre</FormLabel>
                <InputGroup >
                  <Input type='text' placeholder='Nombre' 
                    value={formik.values.name}
                    isInvalid={formik.touched.name && formik.errors.name}
                    isRequired
                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  />
                  {/* {
                    formik.touched.name && formik.errors.name && <InputRightElement children={<HiX className='text-xl text-red-600' />} />

                  } */}

                </InputGroup>
                {formik.touched.name && formik.errors.name && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.name}</FormHelperText>}
                <FormLabel htmlFor='description' >Descripción</FormLabel>
                <Textarea placeholder='' 
                  value={formik.values.description}
                  isInvalid={formik.touched.description && formik.errors.description}
                  isRequired
                  onChange={(e) => formik.setFieldValue('description', e.target.value)}
                />
                {formik.touched.description && formik.errors.description && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.description}</FormHelperText>}
                {/* If you add the size prop to `InputGroup`, it'll pass it to all its children. */}
                <div className='flex md:flex-row gap-1 items-center justify-between'>
                  <div>
                    <FormLabel htmlFor='mb' >Megas</FormLabel>
                    <InputGroup >
                      <Input type='number' placeholder='20' 
                        value={formik.values.megas}
                        isInvalid={formik.touched.megas && formik.errors.megas}
                        isRequired
                        onChange={(e) => formik.setFieldValue('megas', e.target.value)}
                      />
                      <InputRightAddon children='mb' />
                    </InputGroup>
                    {formik.touched.megas && formik.errors.megas && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.megas}</FormHelperText>}
                  </div>
                  <div>
                    <FormLabel htmlFor='price'  >Precio</FormLabel>
                    <InputGroup >
                      <InputLeftElement
                        pointerEvents='none'
                        fontSize='1.2em'
                        children='$'
                      />
                      <Input type='number'  placeholder='20'
                        value={formik.values.price}
                        isInvalid={formik.touched.price && formik.errors.price}
                        isRequired
                        onChange={(e) => formik.setFieldValue('price', e.target.value)}
                      />

                    </InputGroup>
                    {formik.touched.price && formik.errors.price && <FormHelperText color='red.400' className="text-red-600 text-xs">{formik.errors.price}</FormHelperText>}
                  </div>
                </div>
              </Stack>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost'  mr={3} onClick={close}>
              Cerrar
            </Button>
            <Button colorScheme='yellow' onClick={() => formik.handleSubmit()}> {!item?._id ? 'Agregar' : 'Actualizar'}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalPlans;

function initialValues () {
  return {
    name: '',
    description: '',
    megas: '',
    price: ''

  }
}

function validationSchema () {
  return {
    name: Yup.string().required('El nombre es requerido'),
    description: Yup.string().required('Es necesaría una descripción'),
    megas: Yup.number().required('Ingresa el numero de megas'),
    price: Yup.number().required('Ingresa el precio'),
  }
}