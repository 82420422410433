import React, { useState } from 'react'
import { currencyFormat } from 'simple-currency-format';
import { Box, Text, Divider, IconButton } from '@chakra-ui/react';
import { HiChevronUp, HiChevronDown } from 'react-icons/hi'
import dayjs from 'dayjs';


const Record = ({ item, dashboard = false }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <Box key={item._id} className={`${dashboard ? 'border-b':'border rounded-lg'} p-4 flex flex-col w-full my-2 `}>
{ !dashboard && <Box className='flex flex-row justify-end'>
          <IconButton size='sm' icon={show ? <HiChevronUp /> : <HiChevronDown />} onClick={() => setShow(!show)} />
        </Box>}
        <Box className={`grid grid-cols-2 md:grid-cols-4 w-full ${show ? 'pb-4' : 'pb-0'} place-items-center gap-2`}>
          <Text>{dayjs(item?.created_date).format('DD/MMM/YYYY')}</Text>
          <Text>{item.type_contract}</Text>
          <Text>{item.num_account}</Text>
          <Text>{currencyFormat(item.amount, 'es-MX', 'MXN', 2)}</Text>

        </Box>
        {show ? <>
          <Divider />
          <Box className='px-2 py-1 mb-2'>
            <Text className='text-xs font-light my-4 '>Comentarios:</Text>
            <Text className='text-sm font-normal'> {item.comments}</Text>
          </Box>
          <Divider />
          <Box className='grid grid-cols-1 md:grid-cols-2 px-2 py-1'>
            <Box>
              <Text className='text-xs font-light my-4 '>Información del cliente</Text>
              <Box className='grid grid-cols-1 md:grid-cols-2'>
                <Box>
                  <Box className='flex flex-row gap-2'>
                    <Text className='text-sm font-normal'>Nombre:</Text>
                    <Text className='text-sm font-normal'> {item.full_name}</Text>
                  </Box>
                  <Box className='flex flex-row gap-2'>
                    <Text className='text-sm font-normal'>Correo:</Text>
                    <Text className='text-sm font-normal'> {item.email}</Text>
                  </Box>
                </Box>
                <Box>
                  <Box className='flex flex-row gap-2'>
                    <Text className='text-sm font-normal'>Tel:</Text>
                    <Text className='text-sm font-normal'> {item.phone}</Text>
                  </Box>
                  <Box className='flex flex-row gap-2'>
                    <Text className='text-sm font-normal'>Cel:</Text>
                    <Text className='text-sm font-normal'> {item.cellphone}</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Text className='text-xs font-light my-4 '>Información de la cuenta</Text>
              <Box className='grid grid-cols-1 md:grid-cols-2'>
                <Box>
                  <Box className='flex flex-row gap-2'>
                    <Text className='text-sm font-normal'>Referencia de pago:</Text>
                    <Text className='text-sm font-normal'> {item.payment_reference}</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
          :
          null}


      </Box>
    </>
  )
}

export default Record
