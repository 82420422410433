import React, { useState } from 'react'
import { MdOutlineAccountCircle, MdOutlineMail, MdOutlinePhone, MdOutlinePhoneIphone, MdExpandMore, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import {
  Tag,
  Tr,
  Td,
  Button,
  Text,
  Box
} from '@chakra-ui/react'

const RowHome = ({ item, renovar, today, tenDays, openModal }) => {
  const [show, setShow] = useState(false)
  const hoy = Date.parse(today);
  const downDate = Date.parse(today);
  const warningDate = Date.parse(tenDays);
  const limit_date_billing = Date.parse(dayjs(item?.limit_date_billing).format('YYYY-MM-DD'))


  return (
    <>
      <Tr className=' py-4 px-5 '
        key={item._id}
      >
        <Td>
          {downDate > limit_date_billing && <Tag className='mr-2' colorScheme='red'>Vencido</Tag>}
          {hoy === limit_date_billing && <Tag className='mr-2' colorScheme='orange'>Hoy Vence</Tag>}
          {downDate < limit_date_billing && warningDate >= limit_date_billing && <Tag className='mr-2' colorScheme='yellow'>Vencera pronto</Tag>}
          {warningDate < limit_date_billing && <Tag className='mr-2' colorScheme='green'>Al corriente</Tag>}

        </Td>
        <Td>{dayjs(item?.date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase()}</Td>
        <Td>

          {dayjs(item?.limit_date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase()}
        </Td>
        <Td className=' py-4 px-5'>
          {item.num_account}
        </Td>

        <Td align='left'>

          {/* {item?.type_contract === 'Postpago' &&
            <Tooltip label="Renovar x 30 días">
              <Button variant='solid' colorScheme="blackAlpha" onClick={() => openModal(item)} >
                Renovar
              </Button>
            </Tooltip>}
          {item?.type_contract === 'Prepago' && <Menu  >
            <MenuButton variant='solid' colorScheme="blackAlpha" as={Button} rightIcon={<MdExpandMore />}>
              Renovar
            </MenuButton>
            <MenuList bg='gray.800' color="gray.500" borderColor="gray.700">
              <MenuOptionGroup title='Renovar por día'>
                <MenuItem onClick={() => renovar(item, 'DAY', 3)} >3 días</MenuItem>
                <MenuItem onClick={() => renovar(item, 'DAY', 7)} >7 días</MenuItem>
                <MenuItem onClick={() => renovar(item, 'DAY', 15)} >15 días</MenuItem>
              </MenuOptionGroup>
              <MenuDivider />
              <MenuOptionGroup title='Renovar por Mes'>
                <MenuItem onClick={() => renovar(item, 'MONTH', 1)} >1 mes</MenuItem>
                <MenuItem onClick={() => renovar(item, 'MONTH', 3)} >3 meses</MenuItem>
                <MenuItem onClick={() => renovar(item, 'MONTH', 6)} >6 meses</MenuItem>
              </MenuOptionGroup>
              <MenuDivider />
              <MenuOptionGroup title='Renovar por Año'>
                <MenuItem onClick={() => renovar(item, 'YEAR', 1)} >1 año</MenuItem>
                <MenuItem onClick={() => renovar(item, 'YEAR', 2)} >2 años</MenuItem>
              </MenuOptionGroup>
            </MenuList>
          </Menu>} */}
          <Button variant='outline' colorScheme="yellow" onClick={() => openModal(item)} >
            Renovar
          </Button>
          <Button className='mx-2' variant='solid'  onClick={() => setShow(!show)}>{show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Button>
        </Td>
      </Tr>
      {show && <Tr >
        <Td colSpan={5}>
          <Text className='mt-0 pt-0 text-sm font-semibold'>Información de la cuenta</Text>
          <Box>

            <Box className='flex flex-row gap-1 justify-between'>
              <Box className='grid grid-cols-2'>
                <div className='flex flex-row items-center  '><MdOutlineAccountCircle className='mr-1' />{item?.full_name ? item.full_name : 'N/A'}</div>
                <div className='flex flex-row items-center  '><MdOutlineMail className='mr-1' /> {item?.email ? item.email : 'N/A'}</div>
                <div className='flex flex-row items-center  '> <MdOutlinePhone className='mr-1' />{item?.phone ? item.phone : 'N/A'}</div>
                <div className='flex flex-row items-center  '> <MdOutlinePhoneIphone className='mr-1' />{item?.cellphone ? item.cellphone : 'N/A'}</div>
              </Box>
              <Box>
                <div className=' '>Tipo: {item?.type_contract}</div>
                <div className=' '>Plan: {item?.name_type_plan}</div>
                <div className=' '>Costo: ${item?.price_type_plan}</div>
              </Box>
            </Box>
          </Box>
        </Td>
      </Tr>}
    </>
  )
}

export default RowHome
